<template>
  <!-- { label: 'name', value: 'name', children: 'children' } value改为value则是地区编码 -->
  <a-cascader
    v-model="selectedValues"
    :disabled="applyDetail.length > 0 ? !applyDetail.includes('companyAddress') : false "
    :fieldNames="{ label: 'name', value: 'name', children: 'children' }"
    :options="areaData"
    :placeholder="placeholder"
    @change="onChange"
  />
</template>
<script>
/**
 * @author  XuHongli
 * @date  2022/8/29 17:33
 * @version 1.0
 * @description 地区选择器 支持v-model  v-decorator
 */

import areaData from './areas'
export default {
  name: 'AreaSelect',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    applyDetail: {
      type: Array,
      default: () => {[]}
    },
    placeholder: {
      type: String,
      default: '请选择省市区',
    },
    // eslint-disable-next-line vue/require-default-prop
    value: { // 不可以写默认值
      type: Array,
    }
  },
  data() {
    return {
      // 地区数据
      areaData,
      // 选择的数据
      selectedValues: this.value,
    }
  },

  computed: {
    // 支持 v-model 双向数据绑定，如果有新数据会通过 $emit 一个 input 事件进行修改 v-model 的值，也就是当前 value 值。
    // 写法是固定的，vue 自动处理，只管通过 $emit('input', newValue) 抛出去新的值即可。
    currentValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  watch: {
    value(newValue) {
      if (newValue.length) {
        this.selectedValues = newValue
      } else {
        this.selectedValues = []
      }
    },
    currentValue: {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        // 记录最新的值
        this.selectedValues = newValue
        // 判断当前的 value 值是否为 undefined， 如果是的话不用抛出去，要不然 form 标签就会走效验，并提示对应的错误了
        if (newValue !== undefined) {
          // v-decorator 会通过 change 事件接受新值
          this.$emit('change', newValue)
        }
      }
    }
  },
  methods: {
    // 选择好之后的回调
    onChange(value) {
      this.$emit('change', value)
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
